import React from 'react'

const BgImg = (props) => (
  <img
    className="is-hidden-mobile"
    src={props.src}
    alt={props.isRight ? 'right-bg' : 'left-bg'}
    style={{
      maxHeight: 300,
      zIndex: -1,
      position: 'absolute',
      left: props.isRight ? undefined : 0,
      right: props.isRight ? 0 : undefined,
      height: '80%',
      top: '50%',
      transform: 'translateY(-50%)'
    }}
  />
)

BgImg.propTypes = {}

export default BgImg