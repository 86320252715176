import React from "react"
import PropTypes from "prop-types"

import '../../bulma.scss'

import Header from "./Header"
import CopyrightFooter from "./CopyrightFooter"

import { useStaticQuery, graphql } from "gatsby"

const Layout = ({ children, noMenu }) => {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fixed(width: 245, height: 123) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `
  )

  return (
    <>
      <Header images={{ logo: data.logo.childImageSharp.fixed }} noMenu={noMenu} />
      <div className="is-family-primary">
        <main>{children}</main>
      </div>
      <CopyrightFooter />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
