import React from 'react'

import faqBg from '../images/faq-bg.svg'

import Feature from './Feature'
import BgImg from './BgImg'

const FAQ = (props) => (
  <div style={{ position: 'relative' }}>

    <div className="container is-margin-mobile">

      {
        props.noHeader ?
          null
          :
          <>
            <h2 className="title is-2 has-text-info has-text-centered">Najczęściej zadawane pytania:</h2>
            <h6 className="title is-6 has-text-link has-text-centered">I ich odpowiedzi :)</h6>
          </>
      }

      <div className="columns" style={{ paddingTop: 50 }}>

        <div className="column">
          <Feature
            icon={null}
            header={<span className="has-text-success">Kiedy otrzymam dostęp do kursu?</span>}
            content={<p>Dostęp do wszystkich materiałów uzyskasz od razu po zaksięgowaniu wpłaty na podany adres e-mail. Materiały umieszczone są one na naszej specjalnej platformie edukacyjnej, więc w każdej chwili będziesz mieć do nich dostęp.</p>}
          />
        </div>
        <div className="column">
          <Feature
            icon={null}
            header={<span className="has-text-success">Co zrobić jeśli po zakupie nie widzę maila z dostępem?</span>}
            content={<p>Jeśli po kilku minutach od zakupu nie widzisz e-maila z dostępem, sprawdź SPAM! Jeśli go tam również nie, ma wejdź na <a href="https://app.powtorkazpolskiego.pl" target="_blank" rel="noopener noreferrer">stronę platformy</a> i skorzystaj z opcji "Zapomniałeś hasła?" wpisując e-mail podany przy zakupie. Zostanie wtedy wysłana kolejna wiadomość. </p>}
          />
        </div>

      </div>

      {
        props.noPayment ?
          null
          :
          <div className="columns" style={{ paddingBottom: 50 }}>

            <div className="column">
              <Feature
                icon={null}
                header={<span className="has-text-success">W jaki sposób mogę zapłacić za kurs?</span>}
                content={<p>Możesz zapłacić błyskawicznym przelewem poprzez system płatności tpay lub wybrać formę płatności na poczcie. Wówczas po wydrukowaniu specjalnego blankietu, będziesz mógł opłacić dostęp do kursu w dowolnej placówce poczty polskiej.</p>}
              />
            </div>

          </div>
      }

    </div>

    <BgImg src={faqBg} isRight={true} />

  </div>
)

export default FAQ