import React from "react"

import Img from 'gatsby-image'

import './Header.scss'

const Header = (props) => (
  <header>

    <div className="container">

      <nav
        className="navbar has-background-primary is-family-primary"
        style={{
          height: 95,
          borderRadius: '0 0 7px 7px',
          padding: '0 8.3333%'
        }}
      >

        <div className="has-text-centered">
          <Img fixed={props.images.logo} alt={'Logo'} />
        </div>

        {
          props.noMenu ?
            null
            :
            <div className="navbar-end">
              <div className="navbar-menu">
                <a style={{ padding: '0 2rem' }} href="#o-kursie" className="active navbar-item has-text-weight-bold is-text">
                  O kursie
            </a>
                <a style={{ padding: '0 2rem' }} href="#opinie" className="navbar-item has-text-weight-bold is-text">
                  Opinie
            </a>
                <a style={{ padding: '0 2rem' }} href="#kup-kurs" className="navbar-item has-text-weight-bold is-text">
                  Kup kurs
            </a>
              </div>
            </div>
        }

      </nav>

    </div>

  </header>
)

export default Header
