import React from 'react'

const CTAButton = ({style, label, className, ...otherProps}) => (
  <a
    className={'button is-link is-size-3 has-text-weight-bold ' + className}
    style={{
      width: 240,
      borderRadius: 20,
      marginBottom: 50,
      padding: 0,
      height: '3.5rem',
      lineHeight: '3.5rem',
      ...style
    }}
    {...otherProps}
  >
    {label}
  </a>
)

CTAButton.defaultProps = {
  label: 'Kup teraz'
}

export default CTAButton