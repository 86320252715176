import React from 'react'
import PropTypes from 'prop-types'

const Feature = (props) => (
  <div className="column has-background-primary" style={{ height: '100%', padding: '2.5rem', borderRadius: 6 }}>
    <div className="columns is-mobile" style={{ alignItems: 'center' }}>
      {
        props.icon ?
          <img src={props.icon} alt="icon" style={{ height: 78 }} className="is-marginless is-paddingless	" />
          :
          null
      }
      <div className={`column is-hidden-mobile ${props.icon ? 'is-10' : 'is-12'} has-text-danger is-size-5 has-text-weight-bold`} style={props.icon ? { paddingLeft: '2rem' } : {}}>
        {props.header}
      </div>
      <div className={`column is-hidden-tablet ${props.icon ? 'is-6' : 'is-12'} has-text-danger is-size-5 has-text-weight-bold`} style={props.icon ? { marginLeft: '-2rem' } : {}}>
        {props.header}
      </div>
    </div>
    <div>
      {props.content}
    </div>
  </div>
)

Feature.propTypes = {
  icon: PropTypes.string,
  header: PropTypes.node,
  content: PropTypes.node,
}

export default Feature